







import Vue from 'vue';

export default Vue.extend({
  name: 'Default',
  components: {
    TheHeader: () => import('@/components/layouts/TheHeader.vue'),
  },
  methods: {
    fabButtonExist() {
      const FabButton = document.querySelector('#fab-button');
      return FabButton;
    },
  },
  created() {
    this.fabButtonExist();
  },
});
